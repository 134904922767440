import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import uiManager from 'Components/Utils/uiManager'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import WindowActions from 'WindowActions'

class ComponentsTestBB extends Component {
  constructor(props) {
    super(props)
    const { components } = props.data.allComponentsTestsBbJson.edges[0].node
    this.components = components.map((component) => {
      return {
        type: component.type,
        params: component.params,
        components: component.components
      }
    })
    this.uiManager = new uiManager(this.components)
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
    setTimeout(WindowActions.disableScroll, 1000)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
  }
  render() {
    const components = this.uiManager.renderComponents()
    return (
      <Layout>
        <SeoHead
          title="Components Test Page"
          path={this.props.location.pathname}
        />
        {components}
      </Layout>
    )
  }
}

export const ComponentTestsBBQuery = graphql`
query ComponentTestsBBQuery {
  allComponentsTestsBbJson {
    edges {
      node {
        components {
          type
          params {
            height
          }
          components {
            type
            params {
              center
              type
              soundControl
              src
              srcMobile
              extraClasses
            }
          }
        }
      }
    }
  }
}
`

export default ComponentsTestBB
